html {
  font-size: 14px;
  @media (min-width: 768px) {
    font-size: 16px;
  }
  scroll-behavior: smooth;
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fef6e4;
  font-weight: 400;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.bgstyle {
  /* background: linear-gradient(136deg, #1ad7c0, #8E44AD, #ff9b11, #1F3A93, #ddd8c6, #000000, #000000);  */
  /*background: linear-gradient(
    136deg,
    #4484ce,
    #1ad7c0,
    #ff9b11,
    #9b59b6,
    #ff7f7f,
    #ecf0f1
  );*/

  background-size: 1200% 1200%;
  -webkit-animation: BackgroundAnimation 70s ease infinite;
  -moz-animation: BackgroundAnimation 70s ease infinite;
  -o-animation: BackgroundAnimation 70s ease infinite;
  animation: BackgroundAnimation 70s ease infinite;
  height: 100%;
}

.bg-gradient {
  background: linear-gradient(rgba(245, 245, 245, 1), rgba(245, 245, 245, 0));
}

.navbar-transparent {
  background-color: rgba(255, 255, 255, 0);
  transition: background-color 0.2s;
}
.navbar-white {
  background-color: rgba(234, 236, 239, 0.81);
  transition: background-color 0.2s;
}
.brand {
  color: #363636 !important;
}
.nav-item {
  transition: color, 0.2s;
}
.toggler {
  border: none;
  padding: 4px;
  border-radius: 4px;
  &:focus {
    outline: none;
    background-color: rgba(0, 0, 0, 0.05);
  }
}
.socialicons {
  color: white;
  padding-left: 5px;
  padding-right: 5px;
  transition: all 0.8s;
  margin: 4px;
  &:hover {
    color: black;
    padding-left: 5px;
    padding-right: 5px;
    transition: all 0.8s;
    margin: 4px;
  }
}

.title {
  height: 100%;
  left: 0;
  right: 0;
  color: #fff;
  text-align: center;
  margin-top: 0px;
}

.typist {
  min-height: 6rem;
  @media (min-width: 360px) {
    min-height: 4rem;
  }
  @media (min-width: 768px) {
    min-height: 2.5rem;
  }
}

.card {
  transition: transform 0.3s, box-shadow 0.3s;
  &:hover {
    box-shadow: 0 2rem 3rem rgba($black, 0.175) !important;
    transform: translateY(-2px);
    transition: transform 0.3s, box-shadow 0.3s;
  }
}
.skills-tabs {
  flex-grow: 1;
}
.skills-tab {
  flex-grow: 1;
  text-align: center;
}

.progress {
  transform-origin: left;
  & > * {
    transform: scaleX(0);
  }
}
.progress-bar-animation {
  @extend .progress;
  & > * {
    animation: progress 1.5s ease-in-out forwards;
  }
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Muli", sans-serif;
  font-weight: 400;
  margin: 0 0 20px;
  color: inherit;
}

h1 {
  font-size: 46px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 22px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 17px;
}

h6 {
  font-size: 15px;
}

p {
  margin: 0 0 15px;
}


ul,
.teams-block .image .overlay,
.contact-block .contact-info ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

#wrapper {
  overflow: hidden;
  width: 100%;
  position: relative;
}

.container-fluid {
  margin: 0 auto;
  max-width: 1200px;
}

.content-block ul {
  margin: 0 0 15px;
}

.content-block ul li {
  position: relative;
  padding: 0 0 0 20px;
}

.content-block ul li:before {
  content: '\f101';
  font-family: 'fontawesome';
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.3s linear;
  -webkit-transition: all 0.3s linear;
}


.title-holder {
  color: #001858;
  text-align: center;
  margin: 40px 0 40px;
}

.title-holder h2 {
  margin: 0 0 5px;
  text-transform: uppercase;
}

.title-holder .subtitle {
  color: #172c66;
}

.title-holder .subtitle:before {
  content: '-';
  margin: 0 5px 0 0;
}

.title-holder .subtitle:after {
  content: '-';
  margin: 0 0 0 5px;
}

.works-block .portfoliolist>div {
  padding: 15px;
  overflow: hidden;
}

.works-block .portfoliolist>div img {
  position: relative;
  top: 0;
  -webkit-transition: all 600ms cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 600ms cubic-bezier(0.645, 0.045, 0.355, 1);
}

.works-block .portfoliolist>div .label {
  position: absolute;
  height: 60px;
  bottom: -60px;
  left: 0;
  right: 0;
  -webkit-transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
  background: #f582ae;
  padding: 10px;
  color: #fff;
}

.works-block .portfoliolist>div h3 {
  margin: 0 0 5px;
  color: #001858;
  font-size: 16px;
}

.works-block .portfoliolist>div p {
  margin: 0;
  font-size: 12px;
}

.works-block .portfoliolist>div:hover .label {
  bottom: 0;
}

.works-block .portfoliolist>div:hover img {
  transform: scale(1.08);
}

.works-block .portfoliolist .portfolio-wrapper {
  overflow: hidden;
  position: relative !important;
  background: #f3d2c1;
  cursor: pointer;
  height: auto;
  width: 100%;
}

.works-block .portfoliolist .label {
  font-size: 100%;
}

@media only screen and (max-width: 767px) {
  h2 {
    font-size: 20px;
  }

  h3 {
    font-size: 20px;
  }

  .title-holder {
    margin: 0 0 20px;
  }

  .go-top {
    width: 40px;
    height: 40px;
  }

  .go-top:before {
    width: 11px;
    height: 11px;
  }

  .pagetitle {
    padding: 30px 0;
    margin: 56px 0 0;
  }

  .block {
    padding: 40px 0 10px;
  }

  .works-block {
    padding: 40px 0 20px;
  }

  .works-block .portfoliolist>div {
    padding: 0 10px 20px;
  }

}